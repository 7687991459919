import axios, { AxiosError } from "axios";
import Environment from "../environment";

const config = {
  headers: {
    Authorization: `Bearer ${Environment.token}`
  },
};

export interface ApiResult<TResponse> {
  errors: any;
  result: TResponse;
  paging: TResponse;
  success: boolean;
}

export default class ApiService {
  
  static async get<TResponse>(route: string) {
    try {
      var response = await axios.get<ApiResult<TResponse>>(
        Environment.apiUri + route,
        config
      );
      return response.data.result;
    } catch (error) {      
      throw error;      
    }
  }

  static async getNoCatcher<TResponse>(route: string) {
    try {
      var response = await axios.get<ApiResult<TResponse>>(
        Environment.apiUri + route,
        config
      );
      return response.data.result;
    } catch (error) {      
      throw error;      
    }
  }

  static async getParams<TResponse>(route: string, params?: string) {

    try {
      var response = await axios.get<ApiResult<TResponse>>(
        Environment.apiUri + route,
        {
          headers: {
            Authorization: `Bearer ${Environment.token}`
          },
          params: {
            officeId: params
          }
        }
      );
      return response.data.result;
    } catch (error) {      
      throw error;      
    }
  }

  static async getPaginated<TResponse>(route: string) {
    try {
      var response = await axios.get<ApiResult<TResponse>>(
        Environment.apiUri + route,
        config
      );
      const { result, paging } = response.data;
      return { result, paging };
    } catch (error) {      
      throw error;      
    }
  }

  static async patch<TResponse>(route: string, body?: any) {
    try {
      var response = await axios.patch<ApiResult<TResponse>>(
        Environment.apiUri + route,
        body,
        config
      );
      return response.data.result;
    } catch (error) {
      throw error;      
    }
  }

  static async post<TResponse>(route: string, body?: any) {
    try {
      var response = await axios.post<ApiResult<TResponse>>(
        Environment.apiUri + route,
        body,
        config
      );
      return response.data.result;
    } catch (error) {
      throw error;      
    }
  }

  static async put<TResponse>(route: string, body?: any) {
    try {
      var response = await axios.put<ApiResult<TResponse>>(
        Environment.apiUri + route,
        body,
        config
      );
      return response.data.result;
    } catch (error) {      
      throw error;      
    }
  }

  static async delete<TResponse>(route: string) {
    try {
      var response = await axios.delete<ApiResult<TResponse>>(
        Environment.apiUri + route,
        config
      );
      return response.data.success;
    } catch (error) {      
      throw error;      
    }
  }

  static async deleteBody<TResponse>(route: string, body?: any) {

    try {
      var response = await axios.delete(Environment.apiUri + route, {
        headers: {
          Authorization: `Bearer ${Environment.token}`
        },
        data: body
      });

      return response.data.success;
    } catch (error) {      
      throw error;      
    }
  }

}

function catcher(error: AxiosError) {
  if(error.response?.data === ""){
    return window.location.href = '/userdefault'
  }

}
