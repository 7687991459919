import {
  ThreeDots
} from 'react-loader-spinner'

export const Loader = () => {
  const styles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "7rem"
  }
  return (
    <div style={styles}>
      <ThreeDots
        height="100"
        width="100"
        color="#5b5fc7"
        radius="9"
        ariaLabel="three-dots-loading"
      />
    </div>
  )
}
