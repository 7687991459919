import { Context } from "@microsoft/teams-js";
import { createContext, type ReactNode, useState, useEffect } from "react";
import * as microsoftTeams from "@microsoft/teams-js";

type ChildrenContextProps = {
  children: ReactNode;
};
type ProfileContextData = {
  context: Context;
};
export const ProfileContext = createContext({} as ProfileContextData);

export default function ProfileContextProvider({
  children,
}: ChildrenContextProps) {
  const [context, setContext] = useState<any>();

  useEffect(() => {
    microsoftTeams.getContext(async (context) => {
      // Verifica se o usuário está logado no Teams
      // O usuário está logado e concedeu permissão para compartilhar dados
      //console.log(context);
      await setContext(context);
    });
  }, []);

  return (
    <ProfileContext.Provider
      value={{
        context,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
}
