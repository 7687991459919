import ApiService from "../ApiService";

export interface Evaluation {
    id: string;
    identifier: string;
    name: string;
    maximumGrade: string;
    minimunGrade?: string;
    groupOfficeId: string;
  }

  export interface NewEvaluation {    
    identifier: string;
    name: string;
    maximumGrade: string;
    groupOfficeId: string;
  }


  export interface UpdateEvaluation {   
    id: string; 
    identifier: string;
    name: string;
    maximumGrade: string;
    groupOfficeId: string;
  }

  
export default class EvaluationService {
  
      static async list(tenantId?: string, groupOfficeId?: string) {
        return ApiService.get<Evaluation[]>(
          `v1/Evaluation/${tenantId}/${groupOfficeId}/List`
        );
      }

      static async insert(body: NewEvaluation) {        
        return ApiService.post(`v1/Evaluation/Insert`, body);
      }

      static async update(body: UpdateEvaluation) {     
        const evaluationId = body.id;   
        return ApiService.put(`v1/Evaluation/${evaluationId}/Update`, body);
      }

      static async delete(evaluationId: string) {             
        return ApiService.put(`v1/Evaluation/${evaluationId}/Delete`);
      }

}
