import { Route, Routes } from "react-router-dom";

import Grades from "./screens/grades/Grades";
import Frequences from "./screens/frequences/Frequences";
import Tutorial from "./screens/tutorial/Tutorial";
import ProfileContextProvider from "./contexts/ProfileContext";

function BrainzRoutes() {
  return (
    <Routes>
      <Route path="/grades" element={<Grades />} />

      <Route
        path="/frequences"
        element={
          <ProfileContextProvider>
            <Frequences />
          </ProfileContextProvider>
        }
      />
      <Route
        path="/tutorials"
        element={
          <ProfileContextProvider>
            <Tutorial />
          </ProfileContextProvider>
        }
      />
    </Routes>
  );
}

export default BrainzRoutes;
