import { useState, useEffect } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import Avatar from 'react-avatar';
import { Table, Form, Button } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { AiOutlineSearch } from "react-icons/ai";
import { FaFilter, FaCalendarAlt, FaTimes } from "react-icons/fa";
import { BsSearch } from "react-icons/bs";

import UserProfileService, {
  UserProfile,
} from "../../services/UserProfile/UserProfile";
import GroupOfficeService, {
  ClassesGroupOffice,
} from "../../services/GroupOffice/GroupOfficeService";

import TutorialService, {
  StudentsTutorial,
} from "../../services/TutorialGrades/TutorialGradesService";
import { FrequencesStyles } from "./styles";
import { handleIsValidDate, handleVerifyDates } from "../../utils/functions"
import { Loader } from "../../components/Loader";

function TutorialGrades() {
  const [loading, setLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [userProfile, setUserProfile] = useState<UserProfile>();
  const [listClasses, setClasses] = useState<ClassesGroupOffice[]>([]);
  const [listStudentsTutorial, setListStudentsTutorial] = useState<
    StudentsTutorial[]
  >([]);
  const [studentTutorial, setStudentTutorial] = useState<StudentsTutorial[] | any>(
    []
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [isTabOpen, setIsTabOpen] = useState(false);
  const [showFilterDate, setShowFilterDate] = useState(false);
  const [initialDateFilter, setInitialDateFilter] = useState<any>(undefined);
  const [finalDateFilter, setFinalDateFilter] = useState<any>(undefined);
  const [selectedOption, setSelectedOption] = useState(1);
  const [tutorialGradeIdSelected, setTutorialGradeIdSelected] = useState("");
  const [newNoteTutorial, setNewNoteTutorial] = useState("");
  const [frequenceIdSelected, setFrequenceIdSelected] = useState("");
  const [newFrequence, setnewFrequence] = useState<boolean>(false);

  function handleSearchFilter() {
    const initialDate = initialDateFilter?.split(" ")[0];
    const finalDate = finalDateFilter?.split(" ")[0];

    const initialDateIsValid = handleIsValidDate(initialDate);
    const finalDateIsValid = handleIsValidDate(finalDate);

    if (!finalDateIsValid) {
      toast.error("Preencha a data final com um valor válido.")
    }

    if (!initialDateIsValid) {
      toast.error("Preencha a data inicial com um valor válido.")
    }

    if (initialDateIsValid && finalDateIsValid && handleVerifyDates(initialDate, finalDate)) {
      setLoading(true);
      getClassesGroupOffice(false, false, initialDateFilter, finalDateFilter);
      getStudentsTutorial(false, false, initialDateFilter, finalDateFilter);
    }
  }

  useEffect(() => {
    getUserProfile();
    getClassesGroupOffice(true, false, initialDateFilter, finalDateFilter);
    getStudentsTutorial(true, false, initialDateFilter, finalDateFilter);
  }, []);

  const toggleTab = () => {
    setIsTabOpen(!isTabOpen);
  };

  const getUserProfile = async () => {
    microsoftTeams.getContext(async (context) => {
      const userProfile = await UserProfileService.get(
        context?.tid,
        context?.userObjectId,
        context?.groupId
      );

      setUserProfile(userProfile);
      if (userProfile?.typeUserTitle === "Aluno") {
        const student = await TutorialService.list(
          context?.tid,
          context?.groupId,
          false,
          false,
          undefined,
          undefined,
          context?.userObjectId
        );
        setStudentTutorial(student);
      }
    });
  };

  const getClassesGroupOffice = async (
    today?: boolean,
    week?: boolean,
    initialDate?: string,
    finalDate?: string
  ) => {
    microsoftTeams.getContext(async (context) => {
      const classes = await GroupOfficeService.list(
        context?.tid,
        context?.groupId,
        today,
        week,
        initialDate,
        finalDate
      );
      setClasses(classes ?? []);
    });
  };

  const getStudentsTutorial = async (
    today?: boolean,
    week?: boolean,
    initialDate?: string,
    finalDate?: string
  ) => {
    microsoftTeams.getContext(async (context) => {
      const listStudentsTutorial = await TutorialService.list(
        context?.tid,
        context?.groupId,
        today,
        week,
        initialDate,
        finalDate
      );
      setListStudentsTutorial(listStudentsTutorial ?? []);
      setTutorialGradeIdSelected("");
      setNewNoteTutorial("");
      setLoading(false);

      // Apenas para estudantes 
      if (userProfile?.typeUserTitle === "Aluno") {
        const currentStudentData = listStudentsTutorial?.find(item => item.studentOfficeId === context.userObjectId);
        if (currentStudentData) {
          setStudentTutorial([currentStudentData])
        }
      }
    });
  };

  const insertTutorialGrade = async (
    insertTutorialGrade: any,
    message: string
  ) => {
    setIsSaving(true)

    TutorialService.insert(insertTutorialGrade)
      .then((result: any) => {
        if (result) {
          const newList = listStudentsTutorial?.map(student => {
            if (student.studentOfficeId === result.studentOfficeId) {
              const indexToChange = student?.tutorialGrade?.findIndex(tutorial => tutorial.classOfficeId === result.classOfficeId)
              student.tutorialGrade[indexToChange].id = result.id;
              student.tutorialGrade[indexToChange].note = result.note;
            }
            return student;
          })

          setListStudentsTutorial(newList ?? []);
          setTutorialGradeIdSelected("");
          setNewNoteTutorial("");

          toast.success(`${message} lançada com sucesso!`);
        }
        setIsSaving(false)
        return;
      })
      .catch(error => {
        toast.error("Erro ao inserir nota de tutorial, tente novamente.")
        console.error("Erro ao inserir nota de tutorial, tente novamente. Erro => ", error)
        setIsSaving(false)
        return;
      })
  };

  const updateTutorialGrade = async (
    updateTutorialGrade: any,
    message: string
  ) => {
    setIsSaving(true)

    TutorialService.update(updateTutorialGrade)
      .then((result: any) => {
        if (result) {
          const newList = listStudentsTutorial?.map(student => {
            if (student.studentOfficeId === result.studentOfficeId) {
              const indexToChange = student?.tutorialGrade?.findIndex(tutorial => tutorial.id === result.id)
              student.tutorialGrade[indexToChange].note = result.note;
            }
            return student;
          })

          setListStudentsTutorial(newList ?? []);
          setTutorialGradeIdSelected("");
          setNewNoteTutorial("");
          setIsSaving(false)

          toast.success(`${message} lançada com sucesso!`);
        }
        setIsSaving(false)
        return;
      })
      .catch(error => {
        toast.error("Erro ao atualizar nota de tutorial, tente novamente.")
        console.error("Erro ao atualizar nota de tutorial, tente novamente. Erro => ", error)
        setIsSaving(false)
        return;
      })
  };

  const handleDeleteTutorialGrade = async (
    deleteTutorialGradeData: any,
    message: string) => {

    setIsSaving(true)
    TutorialService.delete(deleteTutorialGradeData.id)
      .then((result: any) => {
        if (result) {
          // O resultado da exclusão é um boolean TRUE OR FALSE
          // Se for true estou modificando o objeto (parâmetro)
          // e realizando uma atualização na lista de estudantes
          const newList = listStudentsTutorial?.map(student => {
            if (student.studentOfficeId === deleteTutorialGradeData.studentOfficeId) {
              const indexToChange = student?.tutorialGrade?.findIndex(tutorial => tutorial.id === deleteTutorialGradeData.id)

              student.tutorialGrade[indexToChange].note = null;
              student.tutorialGrade[indexToChange].id = null;
            }
            return student;
          })

          setListStudentsTutorial(newList ?? []);
          setTutorialGradeIdSelected("");
          setNewNoteTutorial("");

          toast.success(`${message} excluída com sucesso!`);
        }
        setIsSaving(false)
        return;
      })
      .catch(error => {
        toast.error("Erro ao excluir nota de tutorial, tente novamente.")
        console.error("Erro ao excluir nota de tutorial, tente novamente. Erro => ", error)
        setIsSaving(false)
        return;
      })
  }

  const handleFaltaChange = async (
    tutorialGrade: any,
    isChecked: any,
    index: any
  ) => {
    if (userProfile?.typeUserTitle !== "Professor") return;
    tutorialGrade.absence = isChecked;
    if (tutorialGrade.absence === true) {
      tutorialGrade.note = "0,0"
    }
    setFrequenceIdSelected(index + "-" + tutorialGrade.studentOfficeId);
    setnewFrequence(isChecked);

    tutorialGrade.typeUser = userProfile?.typeUser;
    if (tutorialGrade.id == null) {
      await insertTutorialGrade(tutorialGrade, "Falta");
    }
    else {
      await updateTutorialGrade(tutorialGrade, "Falta");
    }
  };


  const handleNotaChange = async (
    tutorialGrade: any,
    newNote: string,
    evaluationGradeId: string
  ) => {
    // Only professor can change note
    if (userProfile?.typeUserTitle !== "Professor") return;

    if (newNote !== tutorialGrade.note) {
      // Valida se existem apenas 2 casas após a vírgula
      const match2Digits = newNote?.trim()?.replace(",", ".")?.match(/^\d*\.?\d{0,2}$/);

      if (match2Digits) {
        const numericNewNote = parseFloat(newNote.replace(",", "."));
        const numericNewNoteTutorialState = parseFloat(newNoteTutorial.replace(",", "."));
        const numericMaxGrade = parseFloat(
          tutorialGrade?.maximumGrade?.replace(",", ".")
        );

        if (numericNewNote < 0.0 || numericNewNoteTutorialState < 0.0) {
          toast.error("A nota não pode ser negativa, ajustamos o valor para 0,00.");
          setNewNoteTutorial("0,00");
          tutorialGrade.note = "0,00";
          return false;
        }
        else if (numericNewNote > numericMaxGrade || numericNewNoteTutorialState > numericMaxGrade) {
          toast.error("A nota lançada é maior que a nota máxima, ajustamos o valor para 1,10.");
          setNewNoteTutorial("1,10");
          tutorialGrade.note = "1,10";
          return false;
        } else {
          newNote = newNote.replace(".", ",");
          tutorialGrade.note = newNote;
          setTutorialGradeIdSelected(evaluationGradeId);
          setNewNoteTutorial(newNote);
        }
      }
    }

  };

  const handleNotaChangeSend = async (
    tutorialGrade: any,
  ) => {
    if (userProfile?.typeUserTitle !== "Professor") return;

    // se o usuário deixar o campo nota vazio
    if (tutorialGrade.note === "") {
      if (tutorialGrade.id) {
        // deletar nota
        await handleDeleteTutorialGrade(tutorialGrade, "Nota")
        return;
      }
    }

    if (tutorialGrade.note === null) {
      return;
    }

    tutorialGrade.typeUser = userProfile?.typeUser;
    if (tutorialGrade.id == null) {
      await insertTutorialGrade(tutorialGrade, "Nota");
    }
    else {
      await updateTutorialGrade(tutorialGrade, "Nota");
    }
  };

  const handleSelectChange = async (event: any) => {
    const selectedValue = parseInt(event.target.value);
    setSelectedOption(selectedValue);

    const isOption1 = selectedValue === 1;
    const isOption2 = selectedValue === 2;
    const showFilter = selectedValue === 3;

    setLoading(!showFilter);

    if (selectedValue !== 3) {
      setInitialDateFilter(undefined);
      setFinalDateFilter(undefined);
    }
    if (showFilter) {
      setShowFilterDate(true);
    } else {
      setShowFilterDate(false);
      getClassesGroupOffice(isOption1, isOption2, undefined, undefined);
      getStudentsTutorial(isOption1, isOption2, undefined, undefined);
    }
  };

  const filteredAlunos = listStudentsTutorial.filter((student) =>
    student.studentName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleGoBack = () => {
    setSearchTerm("");
  };

  if (loading && !isTabOpen) {
    return (
      <FrequencesStyles>
        <div className="table-responsive">
          <Loader />
        </div>
      </FrequencesStyles>
    )
  }

  return (
    <FrequencesStyles>
      <Toaster
        position="top-right"
        toastOptions={{
          style: {
            zIndex: "2147483648",
          },
        }}
      />
      <div className="icon-title">
        <div className="align-ava">
          <img
            src={require("../../assets/icons/iconAvaliacao.png")}
            alt="Ícone"
            className="title-icon"
          />
          <h4 className="mt-3">Tutoriais</h4>

          {/* Filtros */}
          <div
            className={`filter-tab ${isTabOpen ? "open" : ""}`}
            onClick={toggleTab}
          >
            <FaCalendarAlt className="calendar-icon" />
          </div>
          <div className={`filter-tab-content ${isTabOpen ? "open" : ""}`}>
            <div className="title-datas">
              <p>Filtros</p>
              <FaFilter className="filter-icon" />
            </div>
            <div className="title-filter">Intervalo de datas</div>
            <div className="section-selectbox">
              <select
                className="selectbox"
                onChange={handleSelectChange}
                value={selectedOption}
              >
                <option value="1">Hoje</option>
                <option value="2">Esta semana</option>
                <option value="3">Personalizado</option>
              </select>
            </div>
            <br />

            <div className={`date-container ${showFilterDate ? "open" : ""}`}>
              <div className="date-cell">
                <label>Data inicial:</label>
                <input
                  className="data-filtercustom"
                  onChange={(event) => {
                    setInitialDateFilter(`${event.target.value}  00:00:00.000`);
                  }}
                  type="date"
                  id="initialDate"
                  name="initialDate"
                  value={initialDateFilter?.split(" ")[0] ?? "dd/mm/aaaa"}
                />
              </div>
              <div className="date-cell">
                <label>Data final:</label>
                <input
                  className="data-filtercustom"
                  onChange={(event) => {
                    setFinalDateFilter(`${event.target.value}  00:00:00.000`);
                  }}
                  type="date"
                  id="finalDate"
                  name="finalDate"
                  value={finalDateFilter?.split(" ")[0] ?? "dd/mm/aaaa"}
                />
              </div>

              <Button
                className="search-button"
                variant="tertiary"
                onClick={handleSearchFilter}
              >
                <BsSearch />
              </Button>
            </div>


            <div className="bottom-buttons">
              <button onClick={toggleTab}>
                <FaTimes className="close-icon" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {userProfile?.typeUserTitle === "Professor" && (
        <>
          <div className="table-responsive">
            {isSaving && (
              <div className="isSavingContainer">
                <Loader />
                <span>Salvando</span>
              </div>
            )}
            {!loading && !isSaving &&
              filteredAlunos.length > 0 &&
              listClasses.length > 0 && (
                // Tabela
                <Table bordered hover responsive className="table">
                  <thead>
                    <tr>
                      <th className="search-cell">
                        <div className="search-container">
                          <Form.Control
                            type="text"
                            placeholder="Pesquisar estudantes"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            id="barra-pesquisa"
                            className="w-100"
                          />
                          <AiOutlineSearch className="search-icon" />
                        </div>
                      </th>
                      {listClasses.map((classes, index) => (
                        <th
                          className="frequence-header"
                          key={"th-" + index + "-" + classes.id}
                          id="text-align-table"
                        >
                          <div className="frequence-cell">
                            <div className="frequence-title-date">
                              {classes.classDate}
                            </div>
                            <div className="frequence-title-hour">
                              {classes.classHour.replace(" 0m", "")}
                            </div>
                          </div>
                          <div className="frequence-subtitle d-flex">
                            <label className="column-av">
                              <span>Nota de Tutorial</span>
                              <span className="rangeGrade">0 - 1,1</span>
                            </label>
                            <label className="column-fr">Falta</label>
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredAlunos?.map((student, index) => (
                      <tr className="table-color" key={index}>
                        <td className="align-middle aluno-cell gap-2">
                          <Avatar name={student.studentName} round={true} size="35" color="#5b5fc7" maxInitials={2} textSizeRatio={2.9} />
                          {student.studentName}
                        </td>
                        {student.tutorialGrade.map((value, index) => {
                          const isDisabled = frequenceIdSelected ===
                            index + "-" + student.studentOfficeId
                            ? newFrequence
                            : value.id != null
                              ? value.absence
                              : false;

                          return (
                            <td
                              key={"td-" + index + "-" + student.studentOfficeId}
                              className="cellNote-color"
                            >
                              <div className="nota-falta">
                                <div>
                                  <input
                                    id={
                                      "nota-" +
                                      index +
                                      "-" +
                                      student.studentOfficeId
                                    }
                                    disabled={isDisabled}
                                    type="text"
                                    value={
                                      tutorialGradeIdSelected === value.id &&
                                        value.id != null
                                        ? newNoteTutorial
                                        : value.note != null
                                          ? value.note
                                          : ""
                                    }
                                    onChange={(e) => {
                                      handleNotaChange(
                                        value,
                                        e.target.value,
                                        value.id!
                                      );
                                    }}
                                    onBlur={() => handleNotaChangeSend(value)}
                                    className="form-control"
                                  />
                                </div>
                                <div>
                                  <input
                                    id={
                                      "falta-" +
                                      index +
                                      "-" +
                                      student.studentOfficeId
                                    }
                                    type="checkbox"
                                    checked={
                                      frequenceIdSelected ===
                                        index + "-" + student.studentOfficeId
                                        ? newFrequence
                                        : value.id != null
                                          ? value.absence
                                          : false
                                    }
                                    onChange={(e) => {
                                      // if (isDisabled) return;
                                      handleFaltaChange(
                                        value,
                                        e.target.checked,
                                        index
                                      );
                                    }}
                                    className="selectionOp"
                                  />
                                </div>
                              </div>
                            </td>
                          )
                        })}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}

            {loading && (
              <div className="table-responsive">
                <Loader />
              </div>
            )}
          </div>

          {!loading && listClasses.length === 0 && (
            <div className="table-responsive">
              <h6 className="mt-1">
                Não existem aulas para este período.
              </h6>
            </div>
          )}

          {!loading && filteredAlunos.length === 0 && searchTerm !== "" && (
            <div className="table-responsive">
              <h6 className="mt-1">
                Nenhum aluno encontrado com as iniciais "{searchTerm}".
              </h6>
              <div>
                <button className="selectbox" onClick={handleGoBack}>
                  Voltar
                </button>
              </div>
            </div>
          )}

          {!loading && filteredAlunos.length === 0 && searchTerm === "" && (
            <div className="table-responsive">
              <h6 className="mt-1">
                Não existem alunos para realizar lançamentos.
              </h6>
            </div>
          )}
        </>
      )}

      {userProfile?.typeUserTitle === "Aluno" && (
        <div className="table-responsive">
          {!loading && studentTutorial?.length > 0 && listClasses?.length > 0 && (
            // Tabela
            <Table bordered hover responsive className="table">
              <thead>
                <tr>
                  <th className="search-cell">
                    <div className="search-container">Aluno(a)</div>
                  </th>
                  {listClasses?.map((classes, index) => {
                    return (
                      <th
                        className="frequence-header"
                        key={"th-" + index + "-" + classes.id}
                        id="text-align-table"
                      >
                        <div className="frequence-cell">
                          <div className="frequence-title-date">
                            {classes.classDate}
                          </div>
                          <div className="frequence-title-hour">
                            {classes.classHour.replace(" 0m", "")}
                          </div>
                        </div>
                        <div className="frequence-subtitle d-flex">
                          <label className="column-av">
                            <span>Nota de Tutorial</span>
                            <span className="rangeGrade">0 - 1,1</span>
                          </label>
                          <label className="column-fr">Falta</label>
                        </div>
                      </th>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {studentTutorial.map((student: any, index: number) => (
                  <tr className="table-color" key={index}>
                    <td className="align-middle aluno-cell gap-2">
                      <Avatar name={student.studentName} round={true} size="35" color="#5b5fc7" maxInitials={2} textSizeRatio={2.9} />
                      {student.studentName}
                    </td>
                    {student.tutorialGrade.map((value: any, index: number) => {
                      return (
                        <td
                          key={"td-" + index + "-" + student.studentOfficeId}
                          className="cellNote-color"
                        >
                          <div className="nota-falta">
                            <div>
                              <input
                                disabled={true}
                                id={
                                  "nota-" + index + "-" + student.studentOfficeId
                                }
                                type="text"
                                value={
                                  tutorialGradeIdSelected === value.id &&
                                    value.id != null
                                    ? newNoteTutorial
                                    : value.note != null
                                      ? value.note
                                      : ""
                                }
                                className="form-control"
                              />
                            </div>
                            <div>
                              <input
                                disabled={true}
                                id={
                                  "falta-" + index + "-" + student.studentOfficeId
                                }
                                type="checkbox"
                                checked={
                                  frequenceIdSelected ===
                                    index + "-" + student.studentOfficeId
                                    ? newFrequence
                                    : value.id != null
                                      ? value.absence
                                      : false
                                }
                                className="selectionOp"
                              />
                            </div>
                          </div>
                        </td>
                      )
                    })}
                  </tr>
                ))}
              </tbody>
            </Table>
          )}

          {loading && (
            <div className="table-responsive">
              <Loader />
            </div>
          )}

          {!loading && listClasses.length === 0 && (
            <div className="table-responsive">
              <h6 className="mt-1">
                Não existem aulas para este período.
              </h6>
            </div>
          )}
        </div>
      )}
    </FrequencesStyles>
  );
}
export default TutorialGrades;
