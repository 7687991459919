import { BrainzAuthContextProvider } from '@brainz_group/webappframework';
import { BrowserRouter } from 'react-router-dom';
import * as microsoftTeams from "@microsoft/teams-js";

import BrainzRoutes from "./BrainzRoutes";

export default function App() {
    microsoftTeams.initialize();
    return (
        <div className="App">
            <BrainzAuthContextProvider waitAuthentication={false}>
                <BrowserRouter >
                    <BrainzRoutes />
                </BrowserRouter>
            </BrainzAuthContextProvider>
        </div>
    )
}