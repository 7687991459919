import ApiService from "../ApiService";

export interface StudentsTutorial  {
    studentOfficeId: string;
    studentName: string;
    tutorialGrade: Array<TutorialGrades>;
}

export interface TutorialGrades {
    id: string | null,
    studentOfficeId: string,
    groupOfficeId: string,
    classOfficeId: string,
    classDate: string,
    maximumGrade : string,
    note: string | null,
    absence: boolean,
    tutorialId?: string,
}

export interface NewTutorial  {    
    studentOfficeId: string,
    groupOfficeId: string,
    classOfficeId: string,
    classDate: string,
    note: string,
    absence: boolean,
    typeUser: number
}

export interface UpdateTutorial   {
    id: string,
    studentOfficeId: string,
    groupOfficeId: string,
    classOfficeId: string,
    classDate: string,
    note: string,
    absence: boolean,
    typeUser: number
}
export default class TutorialService {

    static async list(tenantId?: string, groupOfficeId?: string, today?: boolean, week?: boolean, initialDate?: string, finalDate?: string, studentOfficeId?: string) {

        let url = `v1/TutorialGrade/${tenantId}/${groupOfficeId}/List`

        if(today)
            url =`${url}?today=${today}`; 

        if(week)
            url =`${url}?week=${week}`; 

        if(initialDate != null && finalDate != null)
            url =`${url}?initialDate=${initialDate}&finalDate=${finalDate}`;     

        if(studentOfficeId)
        url =`${url}?studentOfficeId=${studentOfficeId}`;                     
        
        return ApiService.get<StudentsTutorial[]>(url);
    }

    static async insert(body: NewTutorial) {        
        return ApiService.post(`v1/TutorialGrade/Insert`, body);
    }

    static async update(body: UpdateTutorial) {     
        const id = body.id;           
        return ApiService.put(`v1/TutorialGrade/${id}/Update`, body);
    }

    static async delete(id: string) {             
        return ApiService.delete(`v1/TutorialGrade/${id}/Delete`);
      }

 }