import Container from 'react-bootstrap/Container';
import styled from "styled-components";

export const GradesStyles = styled(Container)`
max-width: 100%;
    .icon-title {
        display: flex;
        align-items: center;
        margin-bottom: 2em;
        border-bottom: 2px solid #e3e3e3;
      }
    
    .align-ava{
        display: flex;
        margin-bottom: 0.5em;
    }

    .title-icon {
        width: 34px;
        height: 34px;
        margin-right: 10px;
        margin-top: 0.8rem;
    }

    .mt-3{
        font-weight: 700;
    }

    .table {

        th {
            text-align: center;
            border: none;
        }

        .form-control {
            text-align: center;
            background-color: white;
            border-color: #b2b2b2;
            font-weight: 200;
            color: #7e7f81;
            font-size: 14px;
            width: 70%;
            margin-left: 15%;
        }

        #barra-pesquisa{
            background-color: #ffffff;
            text-align: initial;
            margin: 0px;
            padding: 12px 12px;
        }

        .search-container {
            display: flex;
            align-items: center;
            position: relative;
            margin-bottom: -1em;
        }
        
        .search-icon {
            position: absolute;
            right: 10px; /* Ajuste a posição do ícone conforme necessário */
            top: 50%;
            transform: translateY(-50%);
            color: #666;
            cursor: pointer;
        }

        profile-student{
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
        }

        #img-radius{
            border-radius: 50%;
            margin-left: 1em;
            margin-right: 1em;
        }

        .prova-header{
            background-color: #faf9f8;
            padding: 5px 20px;
            border-radius: 12px;
            border-top: 0.2em solid #f5f5f5;
            border-left: 0.4em solid #f5f5f5;
            border-bottom: 0.4em solid #f5f5f5;
        }

        .prova-cell {
            text-align: center; /* Centraliza o conteúdo horizontalmente */
        }
        
        .prova-title {
            font-size: 12px;
            margin-bottom: 4px;
            font-weight: 600;
        }
        
        .prova-subtitle {
            color: #aaa;
            font-size: 13px;
            font-weight: 100;
        }

        tbody td {
            text-align: center;
            vertical-align: middle;
            padding: 8px 0;
        }

        .table-color{
            background-color: #faf9f8;
        }

        .aluno-cell {
            width: 325px;
            display: flex;
            align-items: center;
            font-size: 12px;
            background-color: #f5f5f5;
            text-align: left;
        }

        .cellNote-color{
            background-color: #ffff;
        }

        .search-cell {
            width: 18%; 
            border-bottom: 1em solid #f5f5f5;
            border-top: 0.2em solid #f5f5f5;
            background-color: #f5f5f5;
        }
    }

    
    table {
        width: 100%;
        border-collapse: collapse;
        overflow: auto;
      }
      
      th, td {
        padding: 8px;
        border: 1px solid #ddd;
        text-align: left;
      }
      
      th {
        background-color: #f2f2f2;
      }
      
      th:first-child,
      td:first-child {
        position: sticky;
        left: 0;
        background-color: #f2f2f2;
      }

      .selectbox{
        font-weight: 600;
        font-size: 16px;
        border-radius: 8px;
        background-color: #f5f5f5;
        border: 3px solid #ccc;
        padding: 6px;
        margin: 6px 0;
      }
`;
