import ApiService from "../ApiService";

 export interface StudentsEvaluation  {
    studentOfficeId: string;
    studentName: string;
    evaluationGrade: Array<EvaluationGrade>;
  }

  export interface EvaluationGrade  {
    evaluationId: string;
    studentOfficeId: string;
    groupOfficeId: string;
    maximumGrade: string;
    note: number | null;
    id: string | null;
  }

  export interface NewEvaluationGrade  {
    evaluationId: string;
    studentOfficeId: string;
    groupOfficeId: string;
    note: string;
    typeUser: number;
  }

  export interface UpdateEvaluationGrade  {
    id: string;
    evaluationId: string;
    studentOfficeId: string;
    groupOfficeId: string;
    note: string;
    typeUser: number;
  }

export default class EvaluationGradeService {

      static async list(tenantId?: string, groupOfficeId?: string, studentOfficeId?: string) {
        let url = `v1/EvaluationGrade/${tenantId}/${groupOfficeId}/List`

        if(studentOfficeId)
        url =`${url}?studentOfficeId=${studentOfficeId}`;                     
        
        return ApiService.get<StudentsEvaluation[]>(url);
        
      }

      static async insert(body: NewEvaluationGrade) {        
        return ApiService.post(`v1/EvaluationGrade/Insert`, body);
        
      }

      static async update(body: UpdateEvaluationGrade) {     
        const evaluationGradeId = body.id;           
        return ApiService.put(`v1/EvaluationGrade/${evaluationGradeId}/Update`, body);
      }

      static async delete(evaluationGradeId: string) {             
        return ApiService.delete(`v1/EvaluationGrade/${evaluationGradeId}/Delete`);
      }
}
