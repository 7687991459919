import ApiService from "../ApiService";

export interface ClassesGroupOffice  {
    id: string,
    subject: string,
    classDate: string,
    classHour: string,
    initialDate: string,
    finalDate: string,
    groupOfficeId: string
}

export default class GroupOfficeService {

    static async list(tenantId?: string, groupOfficeId?: string, today?: boolean, week?: boolean, initialDate?: string, finalDate?: string) {

        let url = `v1/GroupOffice/${tenantId}/${groupOfficeId}/List`

        if(today)
            url =`${url}?today=${today}`; 

        if(week)
            url =`${url}?week=${week}`; 

        if(initialDate != undefined && finalDate != undefined)
            url =`${url}?initialDate=${initialDate}&finalDate=${finalDate}`;                    
        
        return ApiService.get<ClassesGroupOffice[]>(url);
      }

 }